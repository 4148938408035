<template>
  <div>
    Home
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
  },
  data() {
    return {
      title: router.currentRoute.meta.pageTitle,
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
</style>
